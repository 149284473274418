import useAuth from "./useAuth";

const useUserDiscount = () => {
    const {isAuthenticated, getUser, isAgent} = useAuth();

    const hasDiscounts = () => {
        const user = getUser();
        if(!user || Object.keys(user).length == 0 || isAgent()) return false;

        if(isAuthenticated && user?.details != null && Object.keys(user.details).length > 0){
            const userDetails = user.details;
            const hasAvailableDiscounts = userDetails?.discounts?.length || 0 != 0;
            return hasAvailableDiscounts;
        }
        return false;
    }

    const getDiscount = (id, slug) => {
        const discounts = getUser().details.discounts;
        const discount = discounts.find((discount) => discount.user_account_discount_type_id == id || discount.user_account_discount_slug == slug)?.discount_value || false;
        return discount;
    }

    const hasProductDiscounts = () => {
        if(hasDiscounts()){
            return getDiscount(1, 'product-price');
        }
        return false;
    }

    return {hasProductDiscounts}
}

export default useUserDiscount;